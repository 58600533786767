export const seo = {
  url: 'klient/dokumenty',
  title: {
    pl: 'Dokumenty',
    en: 'Documents',
    ua: 'Документи',
  },
  desc: {
    pl: `Zapoznaj się z dokumentami potrzebnymi do realizacji transportu.`,
    en: `Familiarize yourself with the documents needed to carry out the transport.`,
    ua: `Ознайомтеся з документами, необхідними для здійснення перевезення.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'dokumenty'],
}

export const intro = {
  title: {
    pl: 'Dokumenty',
    en: 'Documents',
    ua: 'Документи',
  },
  desc: {
    pl: `Zapoznaj się z dokumentami potrzebnymi do realizacji transportu. `,
    en: `Familiarize yourself with the documents needed to carry out the transport.`,
    ua: `Ознайомтеся з документами, необхідними для здійснення перевезення.`,
  },
}

export const main = {
  title: {
    pl: 'Dokumenty do pobrania<br/>Omida Sea And Air S.A.',
    en: 'Documents to download<br/>Omida Sea And Air S.A.',
    ua: 'Документи для завантаження<br/>Omida Sea And Air S.A.',
  },
  html: {
    pl: `<p>
    Poniżej znajdują się m.in. <strong>upoważnienia do odprawy celnej</strong> w językach: polskim i angielskim oraz <strong>zlecenia spedycyjne</strong> na różne rodzaje transportu. Wybierz plik i pobierz go od razu na swoje urządzenie.
    </p>`,
    en: `<p>
    You can find here <strong>authorizations for customs clearance</strong> in Polish and English and <strong>forwarding orders</strong> for various types of transport. Select the file and download it immediately to your device.
    </p>`,
    ua: `<p>
    Нижче, серед іншого доручення на митне оформлення польською та англійською мовами, а також пересилання замовлень на різні види транспорту. Виберіть файл і завантажте його на свій пристрій.
    </p>`,
  },
}
